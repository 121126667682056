export function convertAngle(angle) {
  return angle >= -180 && angle < 0 ? 360 + angle : angle;
}

export function computeAngle(anglePoint, angleSlice) {
  return anglePoint !== null && angleSlice === convertAngle(anglePoint) ? angleSlice : 360;
}

export function setContentInfo(point, marker) {
  return '<div>' + '<h1>' + marker.title + '</h1>' + 
  '<div>' + '<p><b>Type: </b>' + point.pointType + '</p>' +
  '<p><b>Latitude: </b>' + Number.parseFloat(marker.getPosition().lat().toFixed(6)) +
  '</p><p><b>Longitude: </b>' + Number.parseFloat(marker.getPosition().lng().toFixed(6)) +
  '</p><p><b>Altitude: </b>' + point.altitude + '</p></div></div>'
}