  <template>
  <div style="marginTop: 8px;">
    <a-row>
      <a-col :span="4" :offset="2">
        <a-button @click="cancel()">
          <a-icon type="left" /> {{ $t("common.goBack") }}
        </a-button>
      </a-col>
    </a-row>
    <a-col style="marginTop: 20px;" :span="20" :offset="2">
      <a-card :title="'Batch' + ' - ' + 'Edit'">
        <a-form>
          <a-row>
            <a-col :span="6" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="latitudeChecked"
                @change="(e) => onLatitudeChange(e.target.checked)"
              >
                {{ $t("points.latitude")}}
              </a-checkbox>
            </a-col>
            <a-col :span="6" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('latitude_batch')"
              >
                <a-input-number
                  :disabled="!latitudeChecked"
                  :default-value=point[0].latitude
                  @change="onChangeLatitudeValue"
                  @keyup.enter="() => save()"
                ></a-input-number>
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{
                  form.latitude_batch
                    ? form.latitude_batch
                    : $t("common.noValue")
                }}
              </div>
            </a-col>
          </a-row>
        </a-form>
        <a-form>
          <a-row>
            <a-col :span="6" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="longitudeChecked"
                @change="(e) => onLongitudeChange(e.target.checked)"
              >
                {{ $t("points.longitude") }}
              </a-checkbox>
            </a-col>
            <a-col :span="6" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('d_batch')"
              >
                <a-input-number
                  :disabled="!longitudeChecked"
                  :default-value=point[0].longitude
                  @change="onChangeLongitudeValue"
                  @keyup.enter="() => save()"
                ></a-input-number>
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{
                  form.longitude_batch
                    ? form.longitude_batch
                    : $t("common.noValue")
                }}
              </div>
            </a-col>
          </a-row>
        </a-form>
        <a-form>
          <a-row>
            <a-col :span="6" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="altitudeChecked"
                @change="(e) => onAltitudeChange(e.target.checked)"
              >
                {{$t("points.height")}}
              </a-checkbox>
            </a-col>
            <a-col :span="6" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('ld_batch')"
              >
                <a-input-number
                  :disabled="!altitudeChecked"
                  :default-value=point[0].altitude
                  @change="onChangeAltitudeValue"
                  @keyup.enter="() => save()"
                ></a-input-number>
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{
                  form.altitude_batch
                    ? form.altitude_batch
                    : $t("common.noValue")
                }}
              </div>
            </a-col>
          </a-row>
        </a-form>


        <a-row v-if="isFPManager">
          <a-col
            :xs="{ span: 10, offset: 2 }"
            :sm="{ span: 11, offset: 0 }"
            :md="{ span: 9, offset: 2 }"
            :xl="{ span: 5, offset: 8 }"
            :xxl="{ span: 4, offset: 8 }"
          >
            <a-button
              @click="() => cancel()"
              class="btn btn-danger"
              type="danger"
            >
              {{ $t("form.cancel") }}
            </a-button>
          </a-col>
          <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
            <a-button
              @click="() => save()"
              class="btn"
              type="primary"
            >
              {{ $t("form.save") }}
            </a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Input,
  Button,
  Form,
  Checkbox,
  Card,
  InputNumber,
  Slider
} from "ant-design-vue";
import { isPanValid, isTiltValid } from "../FormValidation";
import { isFPManager } from "../../../utils/userAuth";

Vue.use(Row);
Vue.use(Input);
Vue.use(Button);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(Card);
Vue.use(Slider);

export default {
  name: "batch-props-form",
  components: {
  },
  props: {
    point: Array,
    eventBus: Object,
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
  },
  data() {
    return {
      real_form : [...this.point],
      form : [...this.point],
      latitudeChecked: false,
      longitudeChecked: false,
      altitudeChecked: false,
      invalidFields: [],
      debounce: null,
    };
  },
  mounted() {
//    this.form = []
 //   this.real_form = []
  },
  methods: {
    save() {
      this.eventBus.$emit("save-batch-props", this.real_form);
    },
    onLatitudeChange(checked) {
      this.latitudeChecked = checked;
      if (!this.latitudeChecked) {
        var i = 0
        this.real_form.forEach((id) => {
          if (this.form[i] !== undefined)
          id.latitude = this.form[i].latitude
          this.eventBus.$emit("visualize-change", id);
          i++
        })
      }
      },
  
    onChangeLatitudeValue(value) {
      if (value === "") {
        value = null;
      }
      var copy_form = {...this.real_form}
      this.real_form.length = 0

      let arr = Object.keys(copy_form).map((k) => copy_form[k])
      arr.forEach((id) => {
        var save = {...id}
        save.latitude = value
        this.eventBus.$emit("visualize-change", save);
        this.real_form.push(save)
      })
    },

    onLongitudeChange(checked) {
      this.longitudeChecked = checked;
      if (!this.longitudeChecked) {
        var i = 0
        this.real_form.forEach((id) => {
          if (this.form[i] !== undefined)
          id.longitude = this.form[i].longitude
          this.eventBus.$emit("visualize-change", id);
          i++
        })
      }
    },
    onChangeLongitudeValue(value) {
      if (value === "") {
        value = null;
      }

      var copy_form = {...this.real_form}
      this.real_form.length = 0
      let arr = Object.keys(copy_form).map((k) => copy_form[k])
      arr.forEach((id) => {
        var save = {...id}
        save.longitude = value
        this.eventBus.$emit("visualize-change", save);
        this.real_form.push(save)
      })
    },

    onAltitudeChange(checked) {
      this.altitudeChecked = checked;
      if (!this.altitudeChecked) {
        var i = 0
        this.real_form.forEach((id) => {
          if (this.form[i] !== undefined)
          id.altitude = this.form[i].altitude
          this.eventBus.$emit("visualize-change", id);
          i++
        })
      }
    },
  
    onChangeAltitudeValue(value) {
      if (value === "") {
        value = null;
      }

      var copy_form = {...this.real_form}
      this.real_form.length = 0
      let arr = Object.keys(copy_form).map((k) => copy_form[k])
      arr.forEach((id) => {
        var save = {...id}
        save.altitude = value
        this.eventBus.$emit("visualize-change", save);
        this.real_form.push(save)
      })
    },
    getValidateStatus(prop) {
      const idx = this.invalidFields.indexOf(prop);
      if (idx !== -1) {
        return "error";
      }
      return "";
    },
    cancel() {
      this.form.forEach((id) => {
        this.eventBus.$emit("visualize-change", id);
      })
      this.eventBus.$emit("cancel-batch-props");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-row,
.ant-row-flex {
  margin-bottom: 5px;
}
.save-button {
  display: inline;
  margin-left: 15px;
}

.cancel-button {
  display: inline;
  margin: 8px 0 8px 0;
}
.ant-input-number {
  width: 100%;
  text-align: center;
}
.ant-input {
  width: 100%;
}
</style>
