<template>
	<div id="app">
		<svg id="chart" viewBox="0 0 500 500"></svg>
	</div>
</template>

<script>
import * as d3 from 'd3';
import { iconColors } from "../../MarkerIcon/iconColors"

let chart = []
let yAxis = []
//let lines = []
let x = []
let y = []

function updateMapPosition(e) {
	chart.selectAll('circle')
		.attr('x', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('y', d => y(d.y) * e.transform.k + e.transform.y)
		.attr('cx', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('cy', d => y(d.y) * e.transform.k + e.transform.y)

	chart.selectAll("#rectangle")
		.attr('x', d => (x(d.x) * e.transform.k + e.transform.x) - 5)
		.attr('y', d => (y(d.y) * e.transform.k + e.transform.y) - 5)

	var newX = e.transform.rescaleX(x);
	var newY = e.transform.rescaleY(y);

	yAxis.call(d3.axisLeft(newY))

	chart.selectAll("#triangle")
		.attr('x', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('y', d => (y(d.y) * e.transform.k + e.transform.y))
		.attr("transform", d => `translate(${newX(d.x)}, ${newY(d.y)})`)
}

function handleMouseOverPoint(e) {
	chart
		.append("rect")
		.attr("width", 10 * e.explicitOriginalTarget.__data__.name.length)
		.attr("height", 15)
		.style("fill", "white")
		.style("stroke", "black")
		.style("stroke-width", 0.2)
		.style("pointer-events", "all")
		.attr("x", (e.explicitOriginalTarget.attributes.x.value) - e.explicitOriginalTarget.__data__.name.length * 4.5)
		.attr("y", (e.explicitOriginalTarget.attributes.y.value) - 25)
		.attr("id", "rect_point_hover")
	chart
		.append("text")
		.attr("font-size", 10)
		.attr("text-anchor", "middle")
		.attr("id", "name_point_hover")
		.attr("x", (e.explicitOriginalTarget.attributes.x.value) + 4)
		.attr("y", (e.explicitOriginalTarget.attributes.y.value) - 15)
		.text(e.explicitOriginalTarget.__data__.name);
}

function handleMouseOutPoint() {
	chart.selectAll("#" + "name_point_hover")
		.remove()
	chart.selectAll("#" + "rect_point_hover")
		.remove()
}

export default {
	name: 'point-chart',
	props: {
		eventBus: {
			type: Object
		},
		points: Array,
		longi_latis: String,
	},
	data() {
		return {
			data: [],
			viewpoint: [],
			fallback: [],
			safepoint: [],
			basepoint: [],
			waypoint: [],
			points_show: [],
			points_search: [],
			size: 5,
			prec_id: [],
			type_vision: true,
		}
	},
	watch: {
		longi_latis: {
			deep: true,
			handler() {
				this.longi_lati = this.longi_latis
				this.resetMap()
			}
		},
		points_search: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		points_show: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		type_vision: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		flightplanPoints: {
			deep: true,
			handler() {
				this.flightplanPoints.forEach(point => {
					this.points_search.push(point.id)
				})
				this.resetMap()
			},
		}
	},

	mounted() {
		this.longi_lati = this.longi_latis
		this.eventBus.$on("show-selected-points", this.onShowSelectedPoints);
		this.eventBus.$on("search-selected-points-chart", this.onSearchSelectedPoints);

		this.flightplanPoints.forEach(point => {
			this.points_search.push(point.id)
		})

		this.createChart()
	},

	computed: {
		flightplanPoints() {
			return this.$store.getters.getCurrentFlightPlan().points;
		},
		flightplanPointsSearch() {
			return this.points_show;
		},
	},

	methods: {
		resetMap() {
			this.data = []
			this.viewpoint = []
			this.fallback = []
			this.safepoint = []
			this.basepoint = []
			this.waypoint = []
			this.switchpoint = []
			chart.selectAll("*").remove();
			this.createChart()
		},
		onSearchSelectedPoints(d) {
			this.points_search = d
		},
		onShowSelectedPoints(d) {
			this.points_show = d
		},
		createChart() {
			const width = 500;
			const height = 500;
			const margin = {
				top: 20,
				right: 20,
				bottom: 50,
				left: 60
			};
			chart = d3.select("#chart")
				.append("g")
				.attr("transform", `translate(${margin.left}, ${margin.top})`)

			var zoom = d3.zoom()
				.scaleExtent([.5, 20])
				.extent([
					[0, 0],
					[width, height]
				])
				.on("zoom", updateMapPosition);
			chart
				.append("rect")
				.attr("width", width)
				.attr("height", height)
				.style("fill", "none")
				.style("pointer-events", "all")
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
				.on('click', this.unfilterPoint)
				.call(zoom);

			x = d3.scaleLinear()
				.range([0, width - margin.left - margin.right]);

			y = d3.scaleLinear()
				.range([height - margin.top - margin.bottom, 0]);

			yAxis = chart.append("g");

			chart.append("text")
				.attr("font-size", 10)
				.attr("text-anchor", "middle")
				.attr("transform", "rotate(-90)")
				.attr("x", 0 - ((height - margin.top - margin.bottom) / 2))
				.attr("y", -40)
				.text("Altitude");

			this.createPoints();
    },
    unfilterPoint(d) {
     this.eventBus.$emit("map-clicked", {
        latitude: d.x,
        longitude: d.y,
      })
    },
    filterPoint(d) {
      this.flightplanPoints.forEach((point) => {
        if (point.pointName === d.explicitOriginalTarget.__data__.name)
          this.eventBus.$emit("filter-coordinates", {
            latitude: d.explicitOriginalTarget.__data__.latitude,
            longitude: d.explicitOriginalTarget.__data__.longitude
        });
			})
    },
		createPoints() {
			this.flightplanPoints.forEach((id) => {
				this.data.push({ y: id.altitude, x: id[this.longi_lati] })
			})
			this.data.sort((a, b) => {
				return a.x - b.x;
			});
			if (this.data[0] === undefined)
				x.domain([0, 10]);
			else
				x.domain([this.data[0].x - 0.00005, this.data[this.data.length - 1].x + 0.00005]);
			y.domain([-5, 100]);
			yAxis.call(d3.axisLeft(y));
			var select = this.flightplanPointsSearch
			var selected_points = []
			if (select.length > 0) {
				this.flightplanPoints.forEach(point => {
					if (select.includes(point.pointType) && this.points_search.includes(point.id))
						selected_points.push(point)
				})
			} else {
				this.flightplanPoints.forEach(point => {
					if (this.points_search.includes(point.id))
						selected_points.push(point)
				})
			}
			selected_points.forEach((point) => {
				if (point.pointType === "viewpoint")
					this.viewpoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })
				if (point.pointType === "fallbackpoint")
					this.fallback.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })
				if (point.pointType === "safepoint")
					this.safepoint.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })
				if (point.pointType === "basepoint")
					this.basepoint.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })
				if (point.pointType === "waypoint")
					this.waypoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })
				if (point.pointType === "switchpoint")
					this.switchpoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName, latitude: point.latitude, longitude: point.longitude })

			});
			chart.selectAll()
				.data(this.viewpoint)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', 'red')
				.attr('stroke', '#000')
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);


			chart.selectAll()
				.data(this.fallback)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', iconColors.FALLBACKPOINT)
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);


			chart.selectAll()
				.data(this.basepoint)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', iconColors.BASEPOINT)
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.waypoint)
				.enter()
				.append('rect')
				.attr("x", d => x(d.x) - 5)
				.attr("y", d => y(d.y) - 5)
				.attr("id", 'rectangle')
				.attr("width", 10)
				.attr("height", 10)
				.attr('fill', iconColors.WAYPOINT)
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.switchpoint)
				.enter()
				.append('rect')
				.attr("x", d => x(d.x) - 5)
				.attr("y", d => y(d.y) - 5)
				.attr("id", 'rectangle')
				.attr("width", 10)
				.attr("height", 10)
				.attr('fill', iconColors.SWITCHPOINT)
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.safepoint)
				.enter()
				.append('path')
				.attr("x", d => x(d.x))
				.attr("y", d => y(d.y))
				.attr("transform", d => `translate(${x(d.x)}, ${y(d.y)})`)
				.attr("id", 'triangle')
				.attr('d', d3.symbol().type(d3.symbolTriangle))
				.attr('fill', iconColors.SAFEPOINT)
				.on('click', this.filterPoint)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);
		},
	}
};
</script>

<style>

</style>