<template>
  <div>
    <a-row>
      <a-col
        :xs="{ span: 18, offset: 8 }"
        :sm="{ span: 18, offset: 5 }"
        :md="{ span: 16, offset: 5 }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 8 }"
        :xxl="{ span: 12, offset: 8 }"
      >
        <flightplans-selector style="margin: 0px 0px 30px 15px;" />
      </a-col>
    </a-row>
    <a-row>
      <PointList />
    </a-row>
  </div>
</template>

<script>
import FlightplansSelector from "../FlightplansSelector";
import PointList from "./PointList";

export default {
  name: "pointsEdit",
  components: {
    FlightplansSelector,
    PointList,
  },
};
</script>
