<template>
  <a-row
    type="flex"
    :gutter="16"
  >
    <a-col>
      <div class="geofence-btn-start">
        <a-button
          type="primary"
          icon="radar-chart"
          :disabled="['started', 'completed'].indexOf(geofenceStatus) !== -1"
          @click="onStartBtnClick()"
        >
          {{ $t('points.startGeofence') }}
        </a-button>
      </div>
    </a-col>
    <a-col>
      <div class="geofence-btn-confirm">
        <a-button
          type="primary"
          icon="check"
          :disabled="geofenceStatus !== 'completed'"
          @click="onConfirmBtnClick()"
        >
          {{ $t('points.stopGeofence') }}
        </a-button>
      </div>
    </a-col>
    <a-col>
      <div class="geofence-btn-clear">
        <a-button
          type="primary"
          icon="delete"
          @click="onClearBtnClick()"
          :disabled="!isGeofenceDefined"
        >
          {{ $t('points.clearGeofence') }}
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>

import Vue from 'vue'
import { Button, Row, Col, Icon } from 'ant-design-vue'

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)

export default {
  name: 'geofenceButtons',
  props: {
    eventBus: {
      type: Object
    },
    geofenceStatus: {
      type: String
    }
  },
  computed: {
    isGeofenceDefined () {
      return (this.$store.getters.getCurrentFlightPlan().geofencePoints.length !== 0)
    }
  },
  methods: {
    onStartBtnClick () {
      this.eventBus.$emit('start-geofence')
    },
    onConfirmBtnClick () {
      this.eventBus.$emit('confirm-geofence')
    },
    onClearBtnClick () {
      this.eventBus.$emit('clear-geofence')
    }
  }
}
</script>

<style lang="less" scoped>
  .geofence-btn-start {
    .ant-btn-primary {
      background-color: #F7A740;
      border-color: #EC9D37;
    }
  }
  .geofence-btn-confirm {
    .ant-btn-primary {
      background-color: #6BD781;
      border-color: #72B981;
    }
  }
  .geofence-btn-clear {
    .ant-btn-primary {
      background-color: #EA5757;
      border-color: #C65757;
    }
  }
</style>