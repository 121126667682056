<script>
import Vue from "vue";
import Gmaps from "../../Gmaps";
import { defaultMapCenter } from "../../Gmaps";
import { getColoredIconUrl } from "../../../utils/IconUrlGenerator";
import {
  convertAngle,
  computeAngle,
  setContentInfo,
} from "../../../utils/pointMapManager";
import { isFPManager } from "../../../utils/userAuth";
import { iconColors } from "../../MarkerIcon/iconColors"

let polygon = null;
let clickListener = null;
let globalClickListener = null;
let markerWatched = null;
let forbiddenPan = [];
let markers = [];
let radiusPan = 3;
let visibleMarkers = [];

function numAverage(a) {
  var b = a.length,
      c = 0, i;
  for (i = 0; i < b; i++){
    c += Number(a[i]);
  }
  return c/b;
}

function setPolygonEditable(editable) {
  if (polygon) {
    polygon.setOptions({
      editable: editable,
      draggable: editable,
    });
  }
}

function getPolygonOptions() {
  return {
    fillOpacity: 0,
    strokeColor: "#FF0000",
    fillColor: "#272c67",
    clickable: false,
  };
}

export default {
  name: "points-map",
  mixins: [Gmaps],
  props: {
    eventBus: Object,
    points: Array,
    geofenceStatus: String,
    editingTarget: Object,
    status_edit: String,
  },
  computed: {
    flightplan() {
      return this.$store.getters.getCurrentFlightPlan();
    },
  },
    data() {
    return {
      pointEdited: {},
      pointType: []
    };
  },
  methods: {
    initGeofence() {
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
      });
      this.drawingManager.setMap(this.map);
      this.google.maps.event.addListener(
        this.drawingManager,
        "polygoncomplete",
        (newPolygon) => {
          polygon = newPolygon;
          this.drawingManager.setDrawingMode(null);
          this.eventBus.$emit("set-geofence-status", "completed");
        }
      );

      this.resetGeofence();
    },
    resetGeofence() {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setMap(this.map);
      if (polygon) {
        polygon.setMap(null);
        polygon = null;
      }
      if (this.flightplan.geofencePoints.length > 0) {
        polygon = new this.google.maps.Polygon({
          ...getPolygonOptions(),
          paths: this.flightplan.geofencePoints.map((p) => {
            return new this.google.maps.LatLng(p.latitude, p.longitude);
          }),
          map: this.map,
        });
      }
    },
    resetClickListener() {
      this.google.maps.event.removeListener(clickListener);
      clickListener = null;
    },
    resetMapConfig() {
      const basePoint = this.points.find((p) => p.pointType === "basepoint");
      const mapCenter = basePoint
        ? { lat: basePoint.latitude, lng: basePoint.longitude }
        : defaultMapCenter;

      this.map.setZoom(20);
      this.map.setCenter(mapCenter);
      this.resetClickListener();
    },
    centerMapOnPoint(point) {
      this.map.setCenter({
        lat: point.latitude,
        lng: point.longitude,
      });
      this.map.setZoom(20);
    },
    resetPoints() {
      this.points.forEach((p) => {
        this.addPoint(p);
      });
    },
    addPoint(point) {
      let found = markers.find((markerObj) => markerObj.point.id === point.id);
      if (!found) {
        let marker = this.addMarkerInMap(point);
        let markerObj = {
          point,
          marker: marker,
        };
        markers.push(markerObj);
      } else {
        if (found.longitude != null && found.latitude != null)
          this.refreshPointPosition(point);
      }
    },
    addMarkerInMap(point) {
      let marker = new this.google.maps.Marker({
        position: {
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude),
        },
        icon: {
          url: getColoredIconUrl(
            iconColors[point.pointType.toUpperCase()],
            "#333333",
            point.pointType
          ),
          anchor: { x: 14, y: 14 },
        },
        map: this.map,
        draggable: false,
        title: point.pointName + " / " + "Altitude : " + point.altitude + "m",
      });
      this.addMarkerListener(marker, point);
      return marker;
    },
    addMarkerListener(marker, point) {
      marker.addListener("click", () => {
        this.eventBus.$emit("filter-coordinates", {
          latitude: point.latitude,
          longitude: point.longitude
        });
      });
    },
    onUpdateRadius(point, radius) {
      radiusPan = radius;
      var forbiddenPanObj = forbiddenPan.find((fp) => fp.point.id === point.id);
      if (forbiddenPanObj) {
        forbiddenPanObj.polygons.forEach((p) => p.setMap(null));
        forbiddenPanObj.polygons = [];
        forbiddenPanObj.polygons = this.updateSlices(
          forbiddenPanObj.slices,
          forbiddenPanObj.center,
          radiusPan
        );
      }
    },
    updateSlices(slices, centerPt, radius) {
      let polygons = [];
      slices.forEach((slice) => {
        var path = this.getArcPath(centerPt, radius, slice[0], slice[1]);
        path.unshift(centerPt);
        path.push(centerPt);
        var poly = new this.google.maps.Polygon({
          path: path,
          map: this.map,
          fillColor: slice[2],
          fillOpacity: 0.1,
          strokeColor: slice[2],
          strokeOpacity: 0.35,
        });
        polygons.push(poly);
      });
      return polygons;
    },
    getArcPath(center, radiusMeters, startAngle, endAngle) {
      var computedPoint,
        arcPoints = [];
      endAngle = endAngle === 0 ? 360 : endAngle;
      while (startAngle !== endAngle) {
        computedPoint = this.google.maps.geometry.spherical.computeOffset(
          center,
          radiusMeters,
          startAngle
        );
        arcPoints.push(computedPoint);
        startAngle++;
        if (startAngle > 360) {
          startAngle = 0;
        }
      }
      return arcPoints;
    },
    drawViewpointForbiddenPan(point) {
      var forbiddenPanObj = forbiddenPan.find((fp) => fp.point.id === point.id);
      if (!forbiddenPanObj) {
        var slices = [[0, 360, "green"]];
        var centerPoint = new this.google.maps.LatLng(
          parseFloat(point.latitude),
          parseFloat(point.longitude)
        );
        forbiddenPan.push({
          point: point,
          slices: slices,
          center: centerPoint,
        });
        forbiddenPanObj = forbiddenPan[forbiddenPan.length - 1];
      }
      if (point.viewpointMinForbiddenPan !== null) {
        let value = convertAngle(point.viewpointMinForbiddenPan);
        let max = computeAngle(
          point.viewpointMaxForbiddenPan,
          forbiddenPanObj.slices[0][0]
        );
        forbiddenPanObj.slices[0] = [max, value, "green"];
        forbiddenPanObj.slices[1] = [value, max, "red"];
      }
      if (point.viewpointMaxForbiddenPan !== null) {
        let angle = convertAngle(point.viewpointMaxForbiddenPan);
        let min = computeAngle(
          point.viewpointMinForbiddenPan,
          forbiddenPanObj.slices[0][1]
        );
        forbiddenPanObj.slices[0] = [angle, min, "green"];
        forbiddenPanObj.slices[1] = [min, angle, "red"];
      }

      forbiddenPanObj.polygons = this.updateSlices(
        forbiddenPanObj.slices,
        forbiddenPanObj.center,
        radiusPan
      );
    },
    onUpdateMinForbiddenPan(point) {
      let forbiddenPanObj = forbiddenPan.find((fp) => fp.point.id === point.id);
      if (forbiddenPanObj) {
        let value = convertAngle(point.viewpointMinForbiddenPan);

        if (!isNaN(value)) {
          if (value === null) {
            value = 0;
          }
          this.resetPolygon(forbiddenPanObj);
          let max = computeAngle(
            point.viewpointMaxForbiddenPan,
            forbiddenPanObj.slices[0][0]
          );
          forbiddenPanObj.slices[0] = [max, value, "green"];
          forbiddenPanObj.slices[1] = [value, max, "red"];

          forbiddenPanObj.polygons = this.updateSlices(
            forbiddenPanObj.slices,
            forbiddenPanObj.center,
            radiusPan
          );
        }
      }
    },
    onUpdateMaxForbiddenPan(point) {
      let forbiddenPanObj = forbiddenPan.find((fp) => fp.point.id === point.id);
      if (forbiddenPanObj) {
        let value = convertAngle(point.viewpointMaxForbiddenPan);
        if (!isNaN(value)) {
          if (value === null) {
            value = 0;
          }
          this.resetPolygon(forbiddenPanObj);
          let min = computeAngle(
            point.viewpointMinForbiddenPan,
            forbiddenPanObj.slices[0][1]
          );
          forbiddenPanObj.slices[0] = [value, min, "green"];
          forbiddenPanObj.slices[1] = [min, value, "red"];

          forbiddenPanObj.polygons = this.updateSlices(
            forbiddenPanObj.slices,
            forbiddenPanObj.center,
            radiusPan
          );
        }
      }
    },
    resetPolygon(forbiddenPanObject) {
      forbiddenPanObject.polygons.forEach((p) => p.setMap(null));
      forbiddenPanObject.polygons = [];
    },
    removeForbiddenPan(point) {
      var forbiddenPanObj = forbiddenPan.find((fp) => fp.point.id === point.id);
      if (forbiddenPanObj) {
        forbiddenPanObj.polygons.forEach((p) => p.setMap(null));
        forbiddenPanObj.polygons = [];
      }
      this.resetClickListener();
    },
    onStartGeofence() {
      if (polygon) {
        if (isFPManager) {
          setPolygonEditable(true);
          this.eventBus.$emit("set-geofence-status", "completed");
        }
      } else {
        this.drawingManager.setOptions({
          polygonOptions: {
            ...getPolygonOptions(),
            editable: isFPManager,
            draggable: isFPManager,
          },
        });
        this.drawingManager.setDrawingMode("polygon");
        this.eventBus.$emit("set-geofence-status", "started");
      }
    },
    onConfirmGeofence() {
      if (polygon) {
        const geofencePoints = polygon
          .getPath()
          .getArray()
          .map((p, idx) => {
            return {
              latitude: p.lat(),
              longitude: p.lng(),
              index: idx,
            };
          });

        this.$store
          .dispatch("updateGeofencePoints", {
            flightPlan: this.$store.getters.getCurrentFlightPlan(),
            geofencePoints,
          })
          .then(() => {
            setPolygonEditable(false);
            this.drawingManager.setOptions({
              drawingMode: null,
              polygonOptions: {
                ...getPolygonOptions(),
                editable: false,
                draggable: false,
              },
            });
          });

        this.eventBus.$emit("set-geofence-status", "stopped");
      }
    },
    onClearGeofence() {
      if (polygon) {
        polygon.setMap(null);
        polygon = null;
      }
      this.$store.dispatch("deleteGeofencePoints", {
        flightPlan: this.$store.getters.getCurrentFlightPlan(),
      });

      if (this.geofenceStatus === "completed") {
        this.onStartGeofence();
      }
    },
    onFlightplanChanged() {
      Vue.nextTick().then(() => {
        this.removeAllPoints();
        this.resetMapConfig();
        this.resetGeofence();
        this.resetPoints();
      });
    },
    onVisualizeChange(point) {
      const markerObjIdx = markers.findIndex((m) => m.point.id === point.id);
      if (markerObjIdx !== -1) {
        markers[markerObjIdx].marker.setPosition({
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude),
        });
        markers[markerObjIdx].marker.setIcon({
          url: getColoredIconUrl(
            iconColors[point.pointType.toUpperCase()],
            "#333333",
            point.pointType
          ),
          anchor: { x: 14, y: 14 },
        });
        markers[markerObjIdx].marker.setTitle(point.pointName);
        markers[markerObjIdx].point = point;
      }
    },
    refreshForbiddenPanPosition(point) {
      const forbiddenPanObjIdx = forbiddenPan.findIndex(
        (fp) => fp.point.id === point.id
      );
      if (forbiddenPanObjIdx !== -1) {
        let center = new this.google.maps.LatLng(
          parseFloat(point.latitude),
          parseFloat(point.longitude)
        );
        forbiddenPan[forbiddenPanObjIdx].point = point;
        forbiddenPan[forbiddenPanObjIdx].center = center;
      }
    },
    refreshPointPosition(point) {
      const markerObjIdx = markers.findIndex((m) => m.point.id === point.id);
      if (markerObjIdx !== -1) {
        markers[markerObjIdx].marker.setPosition({
          lat: point.latitude,
          lng: point.longitude,
        });
        if (point.pointType !== markers[markerObjIdx].point.pointType) {
          markers[markerObjIdx].marker.setIcon({
            url: getColoredIconUrl(
              iconColors[point.pointType.toUpperCase()],
              "#333333",
              point.pointType
            ),
            anchor: { x: 14, y: 14 },
          });
        }
        markers[markerObjIdx].point = point;
        this.refreshForbiddenPanPosition(point);
      }
    },
    addAllPoints() {
      this.points.forEach((point) => this.addPoint(point));
    },
    removePoint(point) {
      const markerObjIdx = markers.findIndex((m) => m.point.id === point.id);

      if (markerObjIdx !== -1) {
        markers[markerObjIdx].marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(
          markers[markerObjIdx].marker
        );
        markers.splice(markerObjIdx, 1);
      }
    },
    removeAllPoints() {
      markers.forEach((m) => {
        m.marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(m.marker);
      });
      markers = [];
    },
    onShowSearchedPoints(search_point) {
      visibleMarkers = search_point;
      markers.forEach((markerObj) => {
        if (this.pointType.includes(markerObj.point.pointType)) {
          if (search_point.includes(markerObj.point.id))
            markerObj.marker.setVisible(true);
          else 
            markerObj.marker.setVisible(false);
        } else {
          markerObj.marker.setVisible(false);
        }
        if (this.pointType.length == 0) {
          if (search_point.includes(markerObj.point.id))
            markerObj.marker.setVisible(true);
          else 
            markerObj.marker.setVisible(false);
        }
      });
    },
    onDragAddedPoints (newPoint, oldPoint) {
      var latest_pointCoordinates = {}
      if (oldPoint == null)
        this.pointEdited = {...newPoint}
      if (newPoint == null) {
        latest_pointCoordinates = {...this.pointEdited}
      }
      let markerWatched_oldPoints = null;
      if (oldPoint != null) {
        markerWatched_oldPoints = markers.find((m) => m.point.id === oldPoint.id);
        if (markerWatched_oldPoints != undefined)
          markerWatched_oldPoints.marker.setDraggable(false);
      }
      if (newPoint == null && this.status_edit == "cancel") {
        this.refreshPointPosition(latest_pointCoordinates)
      }
      if (newPoint != null) {
        markerWatched = markers.find((m) => m.point.id === newPoint.id);
        if (markerWatched != undefined) {
          markerWatched.marker.setDraggable(true);
          markerWatched.marker.addListener("dragend", (e) => {
            this.eventBus.$emit("map-clicked", {
              latitude: e.latLng.lat(),
              longitude: e.latLng.lng(),
            });
          });
        }
      }
    },
    onShowSelectedPoints(pointTypes) {
      this.pointType = pointTypes
      if (pointTypes.length === 0) {
        visibleMarkers = [];
        markers.forEach((markerObj) => {
          markerObj.marker.setVisible(true);
        });
      } else {
        visibleMarkers = pointTypes;
        markers.forEach((markerObj) => {
          if (pointTypes.includes(markerObj.point.pointType)) {
            markerObj.marker.setVisible(true);
          } else {
            markerObj.marker.setVisible(false);
          }
        });
      }
    },
  },
  created() {
    this.eventBus.$on("start-geofence", this.onStartGeofence);
    this.eventBus.$on("confirm-geofence", this.onConfirmGeofence),
    this.eventBus.$on("clear-geofence", this.onClearGeofence);
    this.eventBus.$on("flightplan-changed", this.onFlightplanChanged);
  },
  beforeDestroy() {
    this.google.maps.event.removeListener(clickListener);
    this.google.maps.event.removeListener(globalClickListener);
    clickListener = null;
    globalClickListener = null;

    if (this.map.getBounds().lc && this.map.getBounds().Eb) {
      var lat = numAverage([this.map.getBounds().lc.g, this.map.getBounds().lc.i]);
      var lng = numAverage([this.map.getBounds().Eb.g,this.map.getBounds().Eb.i])
      this.$store.commit('SET_CENTER', {
        lat: lat,
        lng: lng
      })
    }
    this.$store.commit("SET_ZOOM", this.map.getZoom())

    this.removeAllPoints();
  },
  async mounted() {
    await this.mapInitPromise;
    this.initGeofence();
    this.resetMapConfig();
    this.resetPoints();
    this.map.setZoom(this.$store.getters.getZoom());
    if (this.$store.getters.getCenter().lat && this.$store.getters.getCenter().lng)
      this.map.setCenter(this.$store.getters.getCenter());
    globalClickListener = this.map.addListener("click", (e) => {
      this.eventBus.$emit("map-clicked", {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      })
    });

    this.eventBus.$on("center-map-on-point", this.centerMapOnPoint);
    this.eventBus.$on("visualize-change", this.onVisualizeChange);

    this.eventBus.$on(
      "draw-viewpoint-forbidden-pan",
      this.drawViewpointForbiddenPan
    );
    this.eventBus.$on("update-min-angle", this.onUpdateMinForbiddenPan);
    this.eventBus.$on("update-max-angle", this.onUpdateMaxForbiddenPan);
    this.eventBus.$on("remove-forbidden-pan", this.removeForbiddenPan);
    this.eventBus.$on("change-radius-forbidden-ban-circle", this.onUpdateRadius);

    this.eventBus.$on("show-shearched-points", this.onShowSearchedPoints);
    this.eventBus.$on("show-selected-points", this.onShowSelectedPoints);
  },
  watch: {
    editingTarget: function(newTarget, oldTarget) {
      this.onDragAddedPoints(newTarget, oldTarget)
      if (newTarget !== null && oldTarget === null) {
        markers.forEach((markerObj) => {
          markerObj.marker.setVisible(true);
        });
        markerWatched = markers.find((m) => m.point.id === newTarget.id);
        if (markerWatched) {
          if (markerWatched.point.id !== "new") {
            this.centerMapOnPoint(markerWatched.point);
          }
          markerWatched.marker.setDraggable(true);
          markerWatched.marker.addListener("dragend", (e) => {
            this.eventBus.$emit("map-clicked", {
              latitude: e.latLng.lat(),
              longitude: e.latLng.lng(),
            });
          });
        }
        clickListener = this.map.addListener("click", (e) => {
          this.eventBus.$emit("map-clicked", {
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
          });
          if (newTarget.id === "new") {
            this.addPoint(newTarget);
          } else {
            this.refreshPointPosition(newTarget);
          }
        });
      } else if (newTarget !== null && oldTarget !== null) {
        this.refreshPointPosition(newTarget);
      } else {
        const newPointMarker = markers.find((m) => m.point.id === "new");

        if (newPointMarker) {
          this.removePoint(newPointMarker.point);
        } else {
          const point = markers.find((m) => m.point.id === oldTarget.id);
          this.refreshPointPosition(point);
        }

        if (visibleMarkers.length !== 0) {
          markers.forEach((markerObj) => {
            if (!visibleMarkers.includes(markerObj.point.pointType)) {
              markerObj.marker.setVisible(false);
            } else {
              markerObj.marker.setVisible(true);
            }
          });
        }

        this.resetClickListener();
        if (markerWatched) {
          markerWatched.marker.setDraggable(false);
          this.google.maps.event.clearListeners(
            markerWatched.marker,
            "dragend"
          );
        }
        markerWatched = null;
      }
    },
    points: function(newPoints, oldPoints) {
      let toDelete = [];
      let toAdd = [];

      oldPoints.forEach((p) => {
        const found = newPoints.find((np) => np.id === p.id);
        if (!found) {
          toDelete.push(p);
        } else if (
          found.latitude !== p.latitude ||
          found.longitude !== p.longitude ||
          found.altitude !== p.altitude
        ){
          toDelete.push(p);
          toAdd.push(found);
        }
      });

      let markerPoints = [];
      markers.forEach((m) => {
        markerPoints.push(m.point);
      });

      newPoints.forEach((np) => {
        var exist = markerPoints.find((mp) => np.id === mp.id);
        if (!exist) {
          toAdd.push(np);
        }
      });

      toDelete.forEach((p) => {
        this.removePoint(p);
      });
      toAdd.forEach((p) => {
        this.addPoint(p);
      });
    },
    filteredData: function(newData) {
      if (newData.length === 0) {
        this.addAllPoints();
      } else {
        this.removeAllPoints();
        newData.forEach((np) => {
          this.addPoint(np);
        });
      }
    },
  },
};
</script>
