<template>
  <div style="marginTop: 8px;">
    <a-row>
      <a-col :span="4" :offset="2">
        <a-button @click="cancel()">
          <a-icon type="left" /> {{ $t("common.goBack") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row style="marginTop: 20px;">
      <a-col :span="20" :offset="2">
        <a-card :title="$t('points.properties')">
          <a-form labelAlign="left" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="First node">
              <a-input v-model="firstNodeVal">
              </a-input>
            </a-form-item>
            <a-form-item label="Second node">
              <a-input v-model="secondNodeVal">
              </a-input>
            </a-form-item>
          </a-form>
          <a-row v-if="isFPManager">
            <a-col
              :xs="{ span: 10, offset: 2 }"
              :sm="{ span: 11, offset: 0 }"
              :md="{ span: 9, offset: 2 }"
              :xl="{ span: 5, offset: 8 }"
              :xxl="{ span: 4, offset: 8 }"
            >
              <a-button
                @click="() => cancel()"
                class="btn btn-danger"
                type="danger"
              >
                {{ $t("form.cancel") }}
              </a-button>
            </a-col>
            <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
              <a-button
                @click="() => save()"
                class="btn"
                type="primary"
              >
                {{ $t("form.save") }}
              </a-button>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Vue from "vue"
import {
  Row,
  Input,
  Button,
  Card,
  Form
} from "ant-design-vue"

import { isFPManager } from "../../../utils/userAuth";

Vue.use(Row)
Vue.use(Input)
Vue.use(Button)
Vue.use(Card)
Vue.use(Form)

export default {
  name: 'switchpoint-props-form',
  props: {
    points: {
      type: [Object, Array]
    },
    eventBus: Object
  },
  data() {
    return {
      firstNodeVal: null,
      secondNodeVal: null
    }
  },
  computed: {
    isFPManager() {
      return isFPManager()
    }
  },
  methods: {
    save() {
      let newPoints = this.points.map(p => {
        let newProps = { "firstNode": this.firstNodeVal, "secondNode": this.secondNodeVal }
        return {...p, ...newProps }
      })
      this.eventBus.$emit("save-switchpoints-props", newPoints);
    },
    cancel() {
      this.eventBus.$emit('cancel-switchpoint-props')
    }
  },
  watch: {
    points: {
      immediate: true,
      handler: function(points) {
        this.firstNodeVal = points[0].firstNode
        this.secondNodeVal = points[0].secondNode
      }
    }
  }
}

</script>